/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import PageIndex from '../../../presentational/pageIndex';
import PaymentSettingDisplay from '../../../presentational/properties/payments/paymentSettingDisplay';
import PaymentModal from '../../../presentational/properties/payments/paymentModal';
import { useTranslation } from 'react-i18next';
import { fetchProperties, fetchResidentTransactions,
         fetchCurrentSubscription, newSubscription, fetchConfiguration, 
         paymentRequest,
         paymentPurchase} from '../../../misc/apiRequests';
import Exit from '../../../../assets/images/exit.svg';
import Checkmark from '../../../../assets/images/payments/check-solid.svg'
import Xmark from '../../../../assets/images/payments/xmark-solid.svg'
import Swal from 'sweetalert2';
import Loader from '../../../presentational/loader';
// import { propertiesData, transactionsData, subscriptionData } from '../../../misc/jsonData/propertyListingData'

const PropertyPayments = ({ user, handleMessagesModal, handleLoader, handleModal }) => {
  const { t } = useTranslation();

  const [paymentData, setPaymentData] = useState(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showPaymentAuth, setShowPaymentAuth] = useState(false);

  const [recentSubscription, setRecentSubscription] = useState(null);
  const [showSubscriptionPlans, setShowSubscriptionPlans] = useState(false);

  const [rentedProperties, setRentedProperties] = useState([]);
  const [pageNumbRental, setPageNumbRental] = useState(1);
  const [maxPagesRental, setMaxPagesRental] = useState(1);
  const [perPageRental, setPerPageRental] = useState(5);

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [paymentModalType, setPaymentModalType] = useState('owned');

  const [basicWeeklyCost, setBasicWeeklyCost] = useState(0.0);
  const [basicMonthlyCost, setBasicMonthlyCost] = useState(0.0);
  const [premiumWeeklyCost, setPremiumWeeklyCost] = useState(0.0);
  const [premiumMonthlyCost, setPremiumMonthlyCost] = useState(0.0);
  const [businessWeeklyCost, setBusinessWeeklyCost] = useState(0.0);
  const [businessMonthlyCost, setBusinessMonthlyCost] = useState(0.0);
  const [maxPropertiesBasic, setMaxPropertiesBasic] = useState(1);
  const [maxPropertiesPremium, setMaxPropertiesPremium] = useState(3);

  const [iframeReloaded, setIFrameReloaded] = useState(1);

  const handleShowSubPlans = (planSelected) => {
    setShowSubscriptionPlans(planSelected)
  }

  // Handle Subscription plan
  const handleSubscriptionPlan = (planSelected, timePeriod = 'week') => {
    setShowSubscriptionPlans(false)

    const date = new Date();
    let expiry = new Date();
    if (timePeriod === 'week') expiry = new Date(expiry.setDate(date.getDate()+7));
    if (timePeriod === 'month') expiry = new Date(expiry.setMonth(date.getMonth()+1));
    let amount = '';
    switch (planSelected) {
      case 'basic':
        if (timePeriod === 'week') amount = basicWeeklyCost;
        if (timePeriod === 'month') amount = basicMonthlyCost;
        break;
      case 'premium':
        if (timePeriod === 'week') amount = premiumWeeklyCost;
        if (timePeriod === 'month') amount = premiumMonthlyCost;
        break;
      case 'business':
        if (timePeriod === 'week') amount = businessWeeklyCost;
        if (timePeriod === 'month') amount = businessMonthlyCost;
        break;
      default:
        amount = basicWeeklyCost;
    }
    // const plan = { subscription: {subscription_plan: planSelected,
    //                               months_active: timePeriod === 'month' ? 1 : 0,
    //                               payment_invoice: null, payment_amount: amount,
    //                               active_date: date.toJSON(),
    //                               expiry_date: expiry.toJSON(),
    //                               active: true} };
    const plan = { subscription: { subscription_plan: planSelected,
                                  subscription_length: timePeriod,
                                  payment_amount: amount } };
    handleLoader(true);
    paymentRequest(plan)
      .then(response => {
        if (response.success) {
          setIFrameReloaded(1);
          handleMessagesModal(response.message);
          // setRecentSubscription(response.record);
          setPaymentData(response.record);
          // console.log(response.record);
          setShowPaymentForm(true);
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
  };

  // Handle Subscription plan
  const handlePaymentCompletion = useCallback(() => {
    const spi_token = paymentData?.SpiToken
    handleLoader(true);
    paymentPurchase(spi_token)
      .then(response => {
        if (response.success) {
          // handleMessagesModal(response.message);
          // setRecentSubscription(response.record);
          // setPaymentData(response.record);
          // console.log(response);
          Swal.fire({
            position: 'middle',
            // toast: true,
            // timerProgressBar: true,
            icon: 'success',
            title: 'Payment was successful',
            showConfirmButton: true,
            // timer: 2000
          })
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
  }, [handleLoader, paymentData]);

  useEffect(() => {
    console.log('iframe', iframeReloaded)
    if (iframeReloaded >= 5 && paymentData.SpiToken) {
      handlePaymentCompletion()
    }
  }, [iframeReloaded, paymentData, handlePaymentCompletion])

  // Handles selection of property when property is clicked
  const handlePaymentSelect = (payment, paymentType = 'owned') => {
    setPaymentModalType(paymentType);
    setSelectedPayment(payment);
  };

  const handlePaymentModal = () => {
    setSelectedPayment(null);
  };

  // Populate all payment settings for rented properties as a tenant
  const populateSubscription = () => (
    <PaymentSettingDisplay
      key={recentSubscription.id}
      paymentInfo={recentSubscription}
      user={user}
      showSubscriptionPlans={showSubscriptionPlans}
      setShowSubscriptionPlans={setShowSubscriptionPlans}
      paymentType="subscription"
    />
  );

  // Populate all payment settings for rented properties as a tenant
  const populateTransactions = () => rentedProperties.map(transaction => (
    <PaymentSettingDisplay
      key={transaction.id}
      paymentInfo={transaction}
      user={user}
      handlePaymentSelect={handlePaymentSelect}
      paymentType="rented"
    />
  ));

  const redirectIframe = () => {
    if(iframeReloaded >= 4) setShowPaymentForm(false);
    // console.log('iframe loaded');
    setIFrameReloaded(iframeReloaded + 1)
  }

  // const paymentAuthModal = (
  //   <div className="modal">
  //     <button
  //       id='navBackDrop'
  //       onClick={() => setShowPaymentAuth(false)}
  //     >
  //     </button>
  //     <div className="modal-content" style={{maxWidth: '1000px', maxHeight: 'none', minHeight: '620px'}}>
  //       <div style={{position: 'relative', width: '100%', height: '100%'}}>
  //         <div></div>
  //       </div>
  //     </div>
  //   </div>
  // );

  const paymentFormModal = (
    <div className="modal">
      <button
        id='navBackDrop'
        onClick={() => setShowPaymentForm(false)}
      >
      </button>
      <div className="modal-content" style={{maxWidth: '1000px', maxHeight: 'none', minHeight: '620px'}}>
        <div style={{position: 'relative', width: '100%', height: '100%'}}>
          <iframe srcDoc={paymentData?.RedirectData} title="Payment Gateway Form" style={{width: '100%', height: '98vh', border: 'none'}} onLoad={() => redirectIframe()}>
          </iframe>
        </div>
      </div>
    </div>
  );

  const subPlanModal = (
    <div className="modal">
      <button
        id='navBackDrop'
        onClick={() => setShowSubscriptionPlans(false)}
      >
      </button>
      <div className="modal-content" style={{backgroundColor: 'transparent'}}>
        {showSubscriptionPlans === 'basic' && (
          <div className='flex-row justify-flex-center'>
            <div className='subscriber-plan'>
              <h3 className='mb-1'>{t('basic')}</h3>
              <ul>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                {t('allowed_to_list')} {maxPropertiesPremium}
              </li>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Tenant Screening Access (TSA)
              </li>
              <li className='mb-1 text-red'>
                <img className='red-filter' src={Xmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Featured Property Listings
              </li>
              <li className='text-red'>
                <img className='red-filter' src={Xmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Tenant’s Reward Program (Coming Soon)
              </li>
                {/* <li>{t('can_accept_tenants')}</li>
                <li>{t('can_accept_payments')}</li> */}
              </ul>
              <div className='flex-row mt-2'>
                <div className='flex-col mr-auto'>
                  <div>${basicWeeklyCost.toFixed(2)} / <b>{t('week')}</b></div>
                  <button type='button' className='mt-1 mb-1'  onClick={() => handleSubscriptionPlan('basic', 'week')}>{t('choose')}</button>
                </div>
                <div className='flex-col'>
                  <div>${basicMonthlyCost.toFixed(2)} / <b>{t('month')}</b></div>
                  <button type='button' className='mt-1 mb-1'  onClick={() => handleSubscriptionPlan('basic', 'month')}>{t('choose')}</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showSubscriptionPlans === 'premium' && (
          <div className='flex-row justify-flex-center'>
            <div className='subscriber-plan premium'>
              <h3 className='mb-1'>{t('premium')}</h3>
              <ul>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                {t('allowed_to_list')} {maxPropertiesPremium}
              </li>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Tenant Screening Access (TSA)
              </li>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Featured Property Listings
              </li>
              <li>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Tenant’s Reward Program (Coming Soon)
              </li>
                {/* <li>{t('can_accept_tenants')}</li>
                <li>{t('can_accept_payments')}</li> */}
              </ul>
              <div className='flex-row mt-2'>
                <div className='flex-col mr-auto'>
                  <div>${premiumWeeklyCost.toFixed(2)} / <b>{t('week')}</b></div>
                  <button type='button' className='mt-1 mb-1'  onClick={() => handleSubscriptionPlan('premium', 'week')}>{t('choose')}</button>
                </div>
                <div className='flex-col'>
                  <div>${premiumMonthlyCost.toFixed(2)} / <b>{t('month')}</b></div>
                  <button type='button' className='mt-1 mb-1'  onClick={() => handleSubscriptionPlan('premium', 'month')}>{t('choose')}</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showSubscriptionPlans === 'business' && (
          <div className='flex-row justify-flex-center'>
            <div className='subscriber-plan business'>
              <h3 className='mb-1'>{t('business')}</h3>
              <ul>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                {t('allowed_to_list_unlimited')}
              </li>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Tenant Screening Access (TSA)
              </li>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Featured Property Listings
              </li>
              <li>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Tenant’s Reward Program (Coming Soon)
              </li>
                {/* <li>{t('can_accept_tenants')}</li>
                <li>{t('can_accept_payments')}</li> */}
              </ul>
              <div className='flex-row mt-2'>
                <div className='flex-col mr-auto'>
                  <div>${businessWeeklyCost.toFixed(2)} / <b>{t('week')}</b></div>
                  <button type='button' className='mt-1 mb-1'  onClick={() => handleSubscriptionPlan('business', 'week')}>{t('choose')}</button>
                </div>
                <div className='flex-col'>
                  <div>${businessMonthlyCost.toFixed(2)} / <b>{t('month')}</b></div>
                  <button type='button' className='mt-1 mb-1'  onClick={() => handleSubscriptionPlan('business', 'month')}>{t('choose')}</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )

  const subscriptionModal = (
    <div className="container-md">
      <div className="messages-container">
        <h2 className='text-white' style={{backgroundColor: 'rgb(109 52 109)', width: '100%', padding: '12px', textAlign: 'center', borderRadius: '4px'}}>Subscription Plans</h2>
        <p>
          Looking to post up listings for others to browse online?
        </p>
        <h2 className='mb-1'>{t('choose_plan')}</h2>
        <div className=''>
          <div className='subscriber-plan'>
            <h3 className='mb-1'>{t('basic')}</h3>
            <div className='mb-2'>
              <span>Starts at</span>
              <br></br>
              <span><b>${basicWeeklyCost.toFixed(2)}</b> /{t('week')}</span>
            </div>
            <ul>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                {t('allowed_to_list')} {maxPropertiesBasic}
              </li>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Tenant Screening Access (TSA)
              </li>
              <li className='mb-1 text-red'>
                <img className='red-filter' src={Xmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Featured Property Listings
              </li>
              <li className='text-red'>
                <img className='red-filter' src={Xmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Tenant’s Reward Program (Coming Soon)
              </li>
            </ul>
            <button onClick={() => handleShowSubPlans('basic')} className='mt-1'>Get Started</button>
          </div>
        </div>
        <div className='subscriber-divider mt-2 mb-2'/>
        <div className=''>
          <div className='subscriber-plan premium'>
            <h3 className='mb-1'>{t('premium')}</h3>
            <div className='mb-2'>
              <span>Starts at</span>
              <br></br>
              <span><b>${premiumWeeklyCost.toFixed(2)}</b> /{t('week')}</span>
            </div>
            <ul>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                {t('allowed_to_list')} {maxPropertiesPremium}
              </li>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Tenant Screening Access (TSA)
              </li>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Featured Property Listings
              </li>
              <li>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Tenant’s Reward Program (Coming Soon)
              </li>
            </ul>
            <button onClick={() => handleShowSubPlans('premium')} className='mt-1'>Get Started</button>
          </div>
        </div>
        <div className='subscriber-divider mt-2 mb-2'/>
        <div className='mb-3'>
          <div className='subscriber-plan business'>
            <h3 className='mb-1'>{t('business')}</h3>
            <div className='mb-2'>
              <span>Starts at</span>
              <br></br>
              <span><b>${businessWeeklyCost.toFixed(2)}</b> /{t('week')}</span>
            </div>
            <ul>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                {t('allowed_to_list_unlimited')}
              </li>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Tenant Screening Access (TSA)
              </li>
              <li className='mb-1'>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Featured Property Listings
              </li>
              <li>
                <img src={Checkmark} style={{width: '14px', marginRight: '6px'}} alt='checkmark'></img>
                Tenant’s Reward Program (Coming Soon)
              </li>
            </ul>
            <button onClick={() => handleShowSubPlans('business')} className='mt-1'>Get Started</button>
          </div>
        </div>
        <div>
          <h2>Why choose Rentalfaqz.com?</h2>
          <p>
            <b>Online Advertisement</b> – Not only does Rentalfaqz.com
            beautifully display your property listing better than any
            newspaper could ever match, but you can also self promote your
            properties through your favorite social media platform directly
            from our site.
          </p>

          <p>
            <b>Professionalism</b> – Say goodbye to the old days of record
            keeping , and add a level of professionalism to your business
            approach. Potential tenants who are interested in your listing can
            now apply directly to your account, providing all the necessary
            information you require, making filing easy and organize.
            
            Set your business hours, so that scheduled property viewings can
            happen on your time. Once a application is approved, units will
            automatically become listed as unavailable as they’re occupied.
            Tenant’s may also report any damages caused, repairs that may
            be needed, or incident that may have occurred on property
            through our message center.            
          </p>

          <p>
            <b>Tenant’s Screening Access (TSA)</b> – It’s important that you
            know just who you are allowing into your business space,
            because it didn’t come easy to you, and we want to help protect it.
            With TSA in your corner, you can screen all new application
            against our network intelligence of gathered reports from
            property owners like yourself. After you’ve decided to list your
            property onto the site, it becomes your responsibility to also get
            your preexisting tenants to register under your account as it is
            now your place of business. (Note) The screening capability of
            TSA is only as good as the reports that you provide on registered
            tenant that will moves throughout the system from property to
            property. There will not be any information listed under first
            time rental owners.
          </p>

          <p>
            <b>Exclusive Reward Offers (ERO)</b> – Rentalfaqz.com
            Exclusive Reward Offer program adds an additional layer
            of value to your rental business. We create the perfect
            ecosystem by offering a way for you to express your gratitude to
            your tenants for choosing to stay with you. We like to say, “ to
            give, is to receive”.
            This reward offer program will deliver exclusive offers and
            discounts that can’t be found anywhere else directly to your
            tenant’s account page. It is our hope to enlist companies like
            Bahamasair, Breezers Resort, Atlantis Resort, Kelly’s Home Décor
            and Appliances, Kravens delivery service, along with your
            favorite fast food restaurant chains, just to name a few.
          </p>

          <p>
            <b>Featured Listing</b> – A featured Listing will put your property
            into rotation amoungst the top 50 to the top 100 listing
            depending on your subscription purchase in the order they are
            received. Property receiving the most views can also push that
            listing to the #1 spot.
            Properties placed within the top 10 listing will have increased its
            chances of being seen by I coming traffic. This simple
            advertisement placement can detour a specific apartment search,
            and make all the difference when it comes to filling out those
            units. You can increase your property listing viewings by
            promoting them on your favorite social media site.
          </p>

          <h2 className='mt-2'>But why subscribe?</h2>
          <p>
            Because like a car insurance, you can never know when there’ll be a
            turn for the worst. The rental market can be unstable, and it can cost
            you thousands of dollars in building repairs and in lost revenue from
            the evictions or the goodbyes of a tenant.
            With our platform, in the face of this uncertainty you can rest easier
            knowing that when this happens you will land on your feet running.
            Because your subscription will not only provide you with a constant
            and beautifully displayed online presence, but it will also provide a
            level of protection within our online community of property owners
            that will provide you with the information you need to make a more
            informed decision of who you should allow to rent your property
            space.
          </p>
        </div>
      </div>
    </div>
  );

  // Grab API Configuration
  useEffect(() => {
    handleLoader(true);
    fetchConfiguration()
      .then(response => {
        if (response.success) {
          setMaxPropertiesBasic(response.record.max_properties);
          setMaxPropertiesPremium(response.record.max_properties_premium);
          setBasicWeeklyCost(Number(response.record.basic_subscription_weekly_cost));
          setBasicMonthlyCost(Number(response.record.basic_subscription_monthly_cost));
          setPremiumWeeklyCost(Number(response.record.premium_subscription_weekly_cost));
          setPremiumMonthlyCost(Number(response.record.premium_subscription_monthly_cost));
          setBusinessWeeklyCost(Number(response.record.business_subscription_weekly_cost));
          setBusinessMonthlyCost(Number(response.record.business_subscription_monthly_cost));
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
  }, [handleLoader, handleModal]);


  // Grab all current residential property transactions by current user
  useEffect(() => {
    handleLoader(true);
    const record = { per_page: perPageRental, page: pageNumbRental, };
    fetchResidentTransactions(record.per_page, record.page)
      .then(response => {
        if (response.success) {
          setRentedProperties(response.records);
          setMaxPagesRental(response.records_params.max_page || 1);
        }
        if (!response.success) handleModal(response.errors);
        handleLoader(false);
      });
  }, [handleLoader, handleModal, pageNumbRental, perPageRental]);

  // Grab current subscription
  useEffect(() => {
    handleLoader(true);
    fetchCurrentSubscription()
      .then(response => {
        if (response.success) {
          setRecentSubscription(response.subscription);
          // !response.subscription && setShowSubscriptionPlans(true)
        }
        if (!response.success) handleModal(response.errors);
        handleLoader(false);
      });
  }, [handleLoader, handleModal]);

  // useEffect(() => {

  // }, [paymentData])

    // Grab all FAKE DATA for properties posted by current user
    // useEffect(() => {
    //     handleLoader(true);
    //     setRecentSubscription(subscriptionData);
    //     const ownedProperties = propertiesData.filter(x => x.owner_id === user.id)
    //     const rentedProperties = transactionsData.filter(x => x.user_id === user.id)

    //     setProperties(ownedProperties);
    //     setMaxPagesOwned(1);
    //     setRentedProperties(rentedProperties);
    //     setMaxPagesRental(1);

    //     handleLoader(false);
    // }, [handleLoader, user.id]);

  return (
    <div className='acct-menu-container'>
      <h1>{t('f_payment')}</h1>
      {user?.roles?.includes('renter') && <div className='mb-1'>
        <h3>{t('recent_sub')}</h3>
        <div className='flex-row mb-2'>
          {
            recentSubscription ? populateSubscription() :
            <span>No recent subscription information available</span>
          }
        </div>
        {showSubscriptionPlans && subPlanModal}
        {subscriptionModal}
        {(showPaymentForm && paymentData) && paymentFormModal}
        {/* <button className='btn mt-1' onClick={() => setShowSubscriptionPlans(true)}>
          Purchase Subscription Plan
        </button> */}
      </div>}

      {user?.roles?.includes('citizen') && (
        <div className='mb-1'>
          <h3>{t('my_home_rental')}</h3>
          {/* <button type='button'>Search by address</button> */}
          {/* <form className='flex-row flex-start pb-1' onSubmit={handleSearchRental}>
            <input type='text'
              className='search-btn'
              value={searchRental}
              onChange={e => setSearchRental(e.target.value)}
              minLength="4"
              required
            />
            <button type="submit" className='button-search'>{t('search')}</button>
          </form> */}
          <div className='flex-row'>{populateTransactions()}</div>
          {maxPagesRental > 1 && (
            <div className='text-center'>
              <PageIndex setPage={setPageNumbRental} setPerPage={setPerPageRental} page={pageNumbRental} perPage={perPageRental} maxPages={maxPagesRental} />
            </div>
          )}
        </div>
      )}

      {selectedPayment && <PaymentModal info={selectedPayment} handleLoader={handleLoader} handleModal={handleModal} handlePaymentModal={handlePaymentModal} />}
    </div>
  );
};

PropertyPayments.propTypes = {
  user: propTypes.instanceOf(Object),
  handleMessagesModal: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired
};

export default PropertyPayments;
